import { Navigator } from "../Components/Navigator/Navigator"
import { RefundForm } from "../Components/RefundForm/RefundForm"
import { ButtonNavigator } from "../Components/ButtonNavigator/ButtonNavigator"
import CustomerInfoWithLogout from "../Components/Customer/Customer"
import { useAuth } from "../Context/AuthContext"
import { ToastContainer, toast } from "react-toastify"
import React, {useEffect, useCallback } from "react"
import useSession from "../CustomHooks/useSession"
const apiUrl = process.env.REACT_APP_API_URL;
export const BankInfoPage = (props) => {
    const [session] = useSession();
    const {  setOrderData, setOrderItems, setTotalPrice, setTotalQuantity } = useAuth();
    const fetchOrderData = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/order-details`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.message.includes('Unauthorized') || errorData.message.includes('session expired')) {
                    toast.error(errorData.message)
                    return;
                }
            }

            const data = await response.json();

            setOrderData(data.order);
        } catch ({ response }) {
            toast.error(response.data.message + "logout")
            console.error('Failed to fetch order data:', response.data.message);
        } finally {
        }
    }, [setOrderData, setOrderItems]);

    useEffect(() => {
        const cachedOrderData = JSON.parse(localStorage.getItem('orderData'));
        const cachedOrderItems = JSON.parse(localStorage.getItem('orderItems'));
        const cachedTotalPrice = localStorage.getItem('totalPrice');
        const cachedTotalQuantity = localStorage.getItem('totalQuantity');
    
        if (cachedOrderData && cachedOrderItems) {
            setOrderData(cachedOrderData);
            setOrderItems(cachedOrderItems);
            setTotalPrice(cachedTotalPrice);
            setTotalQuantity(cachedTotalQuantity);
        }else {
            fetchOrderData(); 

        }
    }, []);
    return (
        <>
            <ToastContainer />
            <CustomerInfoWithLogout />
            <Navigator />
            <RefundForm />
            <ButtonNavigator backLocation={'/product-list'}/>
            <div className="mt-10 mb-10"></div>
        </>
    )
}