import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

const hasSessionExpired = (expiresAt) => {
    return new Date().toISOString() > expiresAt;
};

const useSession = () => {
    const [session, setSession] = useState({
        token: localStorage.getItem('token'),
        expiresAt: localStorage.getItem('expires_at')
    });

    const checkSession = useCallback(() => {
        const { expiresAt } = session;
        if (expiresAt && hasSessionExpired(expiresAt)) {
            toast.error('Session expired. Please log in again.');
            localStorage.clear();
            setTimeout(() => {
                setSession({ token: null, expiresAt: null }); // Clear session state
                window.location.reload();
                return true;
            }, 3000);
        }
        return false;
    }, [session]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (checkSession()) {
                clearInterval(interval); // Stop checking if session is expired
            }
        }, 5000); // Check every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [checkSession]);

    return [session, setSession];
};

export default useSession;
