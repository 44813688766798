import React from 'react';
import { useTranslation } from 'react-i18next';

export const BankInfoConfirmation = ({ id }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full lg:w-2/5 max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-6 bg-gray-100">
        <h1 className="text-2xl font-bold text-center">
          {t('refundRequestConfirmed')} 
        </h1>
      </div>
      
      <div className="p-6">
        <div className="border border-gray-300 p-4 text-center rounded-md">
          <p className="text-lg font-medium">{t('orderId', { order_id: id })}</p>
        </div>
      </div>
    </div>
  );
};
